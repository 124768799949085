import React from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    ImageField,
    TextField,
    NumberField,
    DateField,
    TextInput,
    NumberInput,
    DateInput,
    LongTextInput,
    ReferenceManyField,
    SelectInput,
    ReferenceInput,
    RadioButtonGroupInput,
} from 'react-admin';
import {
  ImageInput
} from './../../components';

 const postDefaultValue = {
  templating: {}
}

 export const UnitCreate = (props) => (
  <Create title="Create Fundraiser" {...props}>
    <TabbedForm redirect="list" defaultValue={postDefaultValue} submitOnEnter={false}>

       <FormTab label="Configuration">

         <ReferenceInput
          label="Choose a parent Subentity"
          source="entity_id"
          reference="subentities"
          perPage={999}
          allowEmpty
        >
          <SelectInput optionText="id" translateChoice={false} />
        </ReferenceInput>
        <TextInput source="slug" label="Subentity Slug" />
        <RadioButtonGroupInput
          source="templating.is_searchable"
          label="Do you want subentity to be searchable?"
          choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' }
          ]}
        />
       </FormTab>

       <FormTab label="Fundraising">
       <br />
       <ImageInput label="Organization Image" source="templating.img" />
       <TextInput source="templating.name" label="Title"/>
       <TextInput source="templating.signature" label="Signature"/>
       <NumberInput source="templating.fundraising.goal" label="Fundraiser Goal"/>
       <DateInput source="templating.fundraising.end_date" label="End Date" />
       <LongTextInput source="templating.fundraising.short_description" label="How donations are used"/>
       <LongTextInput source="templating.fundraising.long_description" label="Special Message"/>
      </FormTab>
     </TabbedForm>
  </Create>
);
