import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    primary: {
      light: '#f08f22',
      main: '#0e9ba6',
      dark: '#1e5e75',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f08f22',
      main: '#0e9ba6',
      dark: '#1e5e75',
      contrastText: '#fff',
    },
  },
};

export default createMuiTheme(theme);
