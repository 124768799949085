import React from 'react';
import {
  minLength,
  maxLength
} from 'react-admin';
import moment from 'moment';
function isValidJSONString(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }
  return false;
}

function _baseValidation(values, errors) {
  if (values.templating
      && (typeof values.templating == "string")
      && !isValidJSONString(values.templating)) {
    errors.templating = ['Must provide valid JSON for templating'];
  }
}

export const Validator = (values) => {
  const errors = {};
   _baseValidation(values, errors);
  if (Object.keys(errors).length) {
    console.error(errors);
  }
  return errors;
};

export const required = value => (value || typeof value === 'number' ? undefined : 'Required')
export const minMaxCharCount = []
export const isValidMoment = value => moment.isMoment( value ) && value.isValid() ? undefined : 'Invalid Date';
export const phoneNumber = value => value && !/^(\()\d{3}(\))(-|\s)?\d{3}(-|\s)\d{4}$/.test(value)  ? 'Invalid phone number, must be 10 digits' : undefined
export const phoneNumberWithoutDashes = [minLength(10), maxLength(10)];
// Char Length Validators
export const xxlCharLen = [minLength(2), maxLength(720)]
export const xlCharLen = [minLength(2), maxLength(500)]
export const lCharLen = [minLength(2), maxLength(375)]
export const mCharLen = [minLength(2), maxLength(300)]
export const xsCharLen = [minLength(2), maxLength(50)]
export const mainPitchCharLen = [minLength(2), maxLength(157)]
