import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    ImageField,
    TextField,
    NumberField,
    DateField,
    TextInput,
    NumberInput,
    DateInput,
    LongTextInput
} from 'react-admin';
import {
  ImageInput
} from './../../components';
import { entityMgrConnect } from './../../services'

const style = theme => ({
  field: {
    width: '100%',
    background: 'red'
  }
});

const SubunitEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const SubunitEditComponent = (props) => (
  <Edit title="Fundraisers" {...props} undoable={false}>
    <TabbedForm  redirect="edit" toolbar={<SubunitEditToolbar />}>
      <FormTab label="Booster Details">
        <ImageInput label="Organization Image" source="templating.img" />
        <TextInput source="templating.name" label="Title"/>
        <NumberInput source="templating.fundraising.goal" label="Fundraiser Goal"/>
      </FormTab>

      <FormTab label="Contact Details">
        <TextField source="entity_manager.first_name" label="First Name"/>
        <TextField source="entity_manager.last_name" label="Last Name"/>
        <TextField source="entity_manager.email" type="email" label="Email"/>
        <TextField source="entity_manager.phone_number" type="tel" label="Phone Number"/>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const SubunitEdit = withStyles(style)(SubunitEditComponent);
