import React from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    ImageField,
    TextField,
    NumberField,
    DateField,
    TextInput,
    NumberInput,
    DateInput,
    LongTextInput,
    ReferenceManyField,
    SelectInput,
    ReferenceInput,
    RadioButtonGroupInput,
} from 'react-admin';
import {
  ImageInput
} from './../../components';

 const postDefaultValue = {
  templating: {}
}

 export const SubentityCreate = (props) => (
  <Create title="Create Fundraiser" {...props}>
    <TabbedForm redirect="list" defaultValue={postDefaultValue} submitOnEnter={false}>

       <FormTab label="Configuration">

         <ReferenceInput
          label="Choose a parent Subentity"
          source="entity_id"
          reference="entities"
          perPage={999}
          allowEmpty
        >
          <SelectInput optionText="id" translateChoice={false} />
        </ReferenceInput>
        <TextInput source="slug" label="Subentity Slug" />
        <RadioButtonGroupInput
          source="templating.is_searchable"
          label="Do you want subentity to be searchable?"
          choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' }
          ]}
        />
       </FormTab>
     </TabbedForm>
  </Create>
);
