import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
  TextInput,
  DisabledInput
} from 'react-admin';
import {
  GreenButton,
  InnerTitle,
  LabeledPhoneInput
} from './../../components';

import {
  formEnhancer
} from './../../services';

import CustomEdit from './../../packages/ra-core/controller/EditController';

import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';

import {
  required,
  phoneNumberWithoutDashes,
  xxlCharLen,
  xlCharLen,
  lCharLen,
  mCharLen,
  xsCharLen
} from './../../validators';

import { createNumberMask, createTextMask } from 'redux-form-input-masks';

const phoneMask = createTextMask({
  pattern: '(999) 999-9999',
});

const styles = {
  save: {
    margin: '30px auto 0',
    textAlign: 'center'
  }
}

class AccountForm extends Component {

  submit(values, action, props) {
    props.save(values, `${props.basePath}`);
  }

  render() {
    return (
      <form>
        <Field
          name="first_name"
          component={TextInput}
          label="First Name"
          validate={[required, ...xsCharLen]}
          hintText="John"
          characterLimit={50}
        />
        <Field
          name="last_name"
          component={TextInput}
          label="Last Name"
          validate={[required, ...xsCharLen]}
          hintText="Smith"
          characterLimit={50}
        />
        <Field
          name="phone_number"
          component={TextInput}
          label="Phone Number"
          type="tel"
          validate={[required, ...phoneNumberWithoutDashes]}
          {...phoneMask}
        />
      <br /><br /><br />
        <Field
          name="email"
          component={DisabledInput}
          label="Email Address"
          disabled
        />
        <div style={styles.save}>
          <GreenButton label="Save Contact Details" onClick={this.props.handleSubmit(this.submit)} />
        </div>
      </form>
    )
  }
}

const EnhanceAccountForm = formEnhancer({
  FormComponent: AccountForm,
  formName: 'edit-account',
  onSubmitFail: (errors, dispatch, submitError, props) => {
    console.log(errors);
  }
});

export const EditAccountForm = (props) => {
  const entityMgr = props.entityMgr || {};
  return (
    <CustomEdit
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="entitymanagers"
      id={entityMgr.id}
      match={{"params":{"id": entityMgr.id}}}
      location={{"pathname":"/account/"}}
      title="&nbsp;"
      basePath="/account"
    >
      <EnhanceAccountForm />
    </CustomEdit>
  )
};
