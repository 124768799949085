import React from 'react';
import {
    BooleanField,
    BulkActions,
    Datagrid,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    TextField,
    TextInput,
} from 'react-admin';
import {
  EditButton,
  ViewButton
} from '../../components/ListButtons';

import BulkAction from './../../components/Approval/BulkAction';

const SubentityFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="where.name.like" alwaysOn />
        <NullableBooleanInput source="where.is_active" label="Is Active" />
    </Filter>
);

const SubentityBulkActions = props => (
    <BulkActions {...props}>
        <BulkAction label="Activate" payloadData={{status: "activate"}}/>
        <BulkAction label="Deactivate" payloadData={{status: "deactivate"}}/>
    </BulkActions>
);

const needActivationStyle = (record, index) => ({
    borderLeft: record.is_active ? '10px solid #fff' : '10px solid rgba(218,59,22, 0.5)',
});

export const SubentityList = (props) => (
    <List {...props} filters={<SubentityFilter />} sort={{ field: 'id', order: 'DESC' }} perPage={25} title="Subentity List" bulkActionButtons={SubentityBulkActions} >
        <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }} rowStyle={needActivationStyle}>
            <TextField source="id" />
            <TextField source="templating.name" label="Name" />
            <TextField source="entity_id" label="Entity ID"/>
            <BooleanField source="is_active" />
            <EditButton />
        </Datagrid>
    </List>
);
