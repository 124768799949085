import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  MenuItemLink,
  getResources,
} from 'react-admin'

import { authClient } from './../../aor-loopback';

const styles = {
  activeStyle: {
    borderLeftWidth: 8,
    borderColor: '#1E5E75',
    borderLeftStyle: 'solid'
  },
  menuStyle: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    font: '16px ProximaNova-Light',
    color: '#464D5E'
  },
  splitter: {
    width: 140,
    height: 1,
    margin: '20px auto',
    backgroundColor: '#7E8890'
  }
}

const CustomMenuItem = (props) => {
  return (
		<MenuItemLink
      style={(props.current === props.to) ? {...styles.menuStyle, ...styles.activeStyle} : styles.menuStyle}
			key={props.to}
			to={`/${props.to}`}
			primaryText={props.primaryText}
			onClick={props.onClick}
		/>
  );
}

class Menu extends Component {

  state = {
    active: null
  }

  setRouteFromState() {
    const active =  this.props.location && this.props.location.pathname.replace(/\//g, "")
      || window.location.pathname.replace(/\//g, "");
    console.log(active);
    this.setState({active});
  }

  componentDidMount() {
    this.setRouteFromState();
    try {
			//this.props.history.listen(this.setRouteFromState.bind(this));
    }
    catch (err) {
      console.error(err);
    }
  }

  _handleClick(to) {
 		console.log(to);
    // this.props.onMenuTap();
    this.props.history.push(to);
    this.setState({ active: to });
  }

  render() {
    return (
      <div>
        <CustomMenuItem
          to={''}
          primaryText={'Dashboard'}
          onClick={this._handleClick.bind(this, '')}
          current={this.state.active}
        />
        {this.props.resources
          .filter(r => r.options.label)
          .map(resource => (
            <CustomMenuItem
              to={resource.name}
              primaryText={resource.options.label}
              onClick={this._handleClick.bind(this, resource.name)}
              current={this.state.active}
            />
          ))
        }
        <div style={styles.splitter}></div>
        <CustomMenuItem
            to={`account`}
            primaryText={`Account`}
            onClick={this._handleClick.bind(this, 'account')}
            current={this.state.active}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
    // Rerieve all known resources
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
