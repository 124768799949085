import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import storage from './../../aor-loopback/storage';
import config from './../../config/config.provider';

import { Card, CardActions } from '@material-ui/core';
import { propTypes, reduxForm, Field } from 'redux-form';

import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import compose from 'recompose/compose'

import {
  translate,
  ViewTitle
} from 'react-admin';

import {
  LabeledInput,
  ExternalLayout,
  RaisedButton
} from './../../components';

import { Link } from "react-router-dom";

import { SubmissionError } from 'redux-form';
import { styles } from './shared';

const apiClient = loopbackRestClient(config);

const BottomLinks = () => {
  return (
    <p style={styles.hint}>
      <Link style={styles.href} to="/login">Login</Link>
      <span> | </span>
      <Link style={styles.href} to="/forgot_password">Reset Password</Link>
    </p>
  );
}

class ResetPassword extends Component {

  state = {
    entityMgr: undefined,
    loading: true
  };

  async componentDidMount() {
    const token = this.props.match.params.token
    apiClient("WITH_ROUTE", "EntityManagers", {route:`getByAccessToken/${token}`}).then(({response}) => {
      if (response.status == 200) {
        storage.save('lbtoken', {id: this.props.match.params.token}, 604800);
        this.setState({entityMgr: response.json, loading: false});
      }
      else {
        this.setState({entityMgr: undefined, loading: false});
      }
    }).catch((err) => {
      this.setState({entityMgr: undefined, loading: false});
      console.error(err);
    });
  }

  onSubmit(e, dispatch) {
    e.nativeEvent.preventDefault();
    dispatch(submit('reset'))
  }

  render() {

    if (this.state.entityMgr) {
      const { error, pristine, reset, submitting, dispatch, submitSucceeded } = this.props;
      return (
        <ExternalLayout>
          <div style={styles.header}>Create Password</div>
          <div style={styles.caption}>Enter and confirm your new password below.</div>
          <form>
            <div style={styles.form}>
              {error && <p style={styles.error}>{error}</p>}
              {submitSucceeded && pristine && <p style={styles.success}>{config.response_messages.reset_password_success}</p>}
              <div style={styles.input}>
                <Field
                  name="newPassword"
                  component={LabeledInput}
                  label="New Password"
                  type="password"
                />
              </div>
              <div style={styles.input}>
                <Field
                  name="newPassword_confirm"
                  component={LabeledInput}
                  label="Confirm New Password"
                  type="password"
                />
              </div>
            </div>
            <CardActions>
              <RaisedButton type="submit" primary disabled={submitting} label="Reset" onClick={(e) => { this.onSubmit(e, dispatch) } } />
            </CardActions>
            <BottomLinks />
          </form>
        </ExternalLayout>
      );
    }
    else if (!this.state.loading) {
      return (
        <ExternalLayout>
          <div style={styles.header}>The link you clicked is invalid or the session has expired.</div>
          <div style={styles.caption}>Please re-submit a password reset request.</div>
        </ExternalLayout>
      );
    }
    else {
      return (
        <ExternalLayout>
  				<div style={styles.header}>Loading...</div>
        </ExternalLayout>
      );
    }
  }
};

export default reduxForm({
    form: 'reset',
    validate: (values, props) => {
        const errors = {};
        if (!values.newPassword)
          errors.newPassword = "Required.";
        else if (!values.newPassword_confirm)
          errors.newPassword_confirm = "Required.";
        else if (values.newPassword !== values.newPassword_confirm)
          errors.newPassword_confirm = "Passwords do not match.";
        return errors;
    },
    onSubmit: (values, dispatch, props) => {
      return new Promise(async (resolve, reject) => {
        const {response} = await apiClient("WITH_ROUTE", "EntityManagers", {
          route:"reset-password",
          data: values,
          method: "POST"
        });
        if (response.status === 204) {
          dispatch(reset('reset'));
          resolve();
        }
        else {
          reject(new SubmissionError({ _error: config.response_messages.reset_password_error}));
        }
      });
    }
})(ResetPassword);
