import React from 'react';
import PropTypes from 'prop-types';
import {
  getFromNestedSafely as gS
} from './../../services';

const styles = {
  list: {
    display: 'flex',
    listStyleType: 'none'
  },
  image: {
    margin: 5,
    height: 40,
    width: 40,
    borderRadius: '50%'
  }
};

export const ListProfileImg = ({
  elStyle = {},
  record,
  source,
  src,
  title
}) => {
  const sourceValue = gS(record, source);
  if (!sourceValue) {
    return <img style={styles.image} src="//cloudfront.startupmedia.com/greenback/misc/graphics/upload-face-preview.png" />;
  }

  if (Array.isArray(sourceValue)) {
    const style = {
      ...styles.list,
      ...elStyle
    };
    return (
      <ul style={style}>
        {
          sourceValue.map((file, index) => {
            const srcValue = gS(file, src);

            return (<li key={index}>
              <img alt={title} title={title} src={srcValue} style={styles.image}/>
            </li>);
          })
        }
    </ul>
    );
  }

  return (<div style={elStyle}>
    <img title={title} alt={title} src={sourceValue} style={styles.image}/>
  </div>);
};

ListProfileImg.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default ListProfileImg;
