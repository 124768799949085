import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import storage from './../../aor-loopback/storage';
import config from './../../config/config.provider';
import {
  CreateAccountForm,
  ExternalLayout
} from './../../components';

import { Link } from "react-router-dom";
import { styles } from './shared';

const apiClient = loopbackRestClient(config);

const BottomLinks = () => {
  return (
    <p style={styles.hint}>
      <Link style={styles.href} to="/login">Login</Link>
      <span> | </span>
      <Link style={styles.href} to="/forgot_password">Reset Password</Link>
    </p>
  );
}

class CreateAccount extends Component {

  state = {
    entityMgr: undefined,
    loading: true
  };

  async componentDidMount() {
    const token = this.props.match.params.token
    apiClient("WITH_ROUTE", "EntityManagers", {route:`getByAccessToken/${token}`}).then(({response}) => {
      if (response.status == 200) {
        storage.save('lbtoken', {id: this.props.match.params.token}, 604800);
        this.setState({entityMgr: response.json, loading: false});
      }
      else {
        this.setState({entityMgr: undefined, loading: false});
      }
    }).catch((err) => {
      this.setState({entityMgr: undefined, loading: false});
      console.error(err);
    });
  }

  render() {
    if (!this.state.loading && !this.state.entityMgr) {
      return (
        <div>
					<div style={styles.header}>The link you clicked is invalid or the session has expired.</div>
          <BottomLinks />
        </div>
      );
    }
    else if (!this.state.loading && this.state.entityMgr) {
      return (
        <div>
          <CreateAccountForm />
          <BottomLinks />
        </div>
      );
    }
    else {
      return (
        <div style={styles.header}>Loading...</div>
      );
    }
  }
}

const CreateAccountWrapper = (props) => {
  return (
    <ExternalLayout>
      <CreateAccount {...props} />
    </ExternalLayout>
  );
}

export default CreateAccountWrapper;
