import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';

import {
  Headroom, 
  Link,
  LoadingIndicator,
  MenuItemLink, 
  UserMenu, 
  toggleSidebar as toggleSidebarAction  
 } from 'react-admin';

import { authClient } from './../../aor-loopback';

const customStyles = {
  bar: {
    position: 'relative',
    width: '100%',
    height: 60,
    background: 'linear-gradient(90deg, #1E5E75 0%, #0E9BA6 63%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  barImg: {
    height: 'auto',
    width: 180,
    display: 'block',
    marginLeft: 20
  },
  hamburger: {
    margin: '0 0 0 20px',
    height: 15,
    width: 'auto',
    cursor: 'pointer',
    display: 'block'
  },
  subTitle: {
    font: "24px 'ProximaNova-Light'",
    color: '#FFF',
    marginLeft: 10,
    lineHeight: 1.4
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  logOut: {
    marginRight: 20
  },
  link: {
    color: '#000',
    textDecoration: 'none',
    font: "14px 'ProximaNova-SemiBold'",
  }
};

const handleLogout = () => {
  console.log('logout');
  return new Promise((resolve) => {
    authClient()("AUTH_LOGOUT").then(resolve);
  });
}

const CustomUserMenu = props => (
  <UserMenu {...props}>
    <MenuItemLink 
      to={"login"} 
      primaryText="Logout" 
      onClick={handleLogout} 
      />
    <MenuItemLink
      to={"account"}
      primaryText={`Account`}
      />
  </UserMenu>
);

const defaultStyles = theme => ({
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});


const AppBar = ({
  children,
  classes,
  className,
  logout,
  open,
  title,
  toggleSidebar,
  userMenu,
  width,
  entityMgr,
  ...rest
}) => (
  <Headroom>
    <MuiAppBar
      className={className}
      color="secondary"
      position="static"
      {...rest}
    >
      <Toolbar
        disableGutters
        variant={width === 'xs' ? 'regular' : 'dense'}
        className={classes.toolbar}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSidebar}
          className={classNames(classes.menuButton)}
        >
          <MenuIcon
            classes={{
              root: open
                ? classes.menuButtonIconOpen
                : classes.menuButtonIconClosed,
            }}
          />
        </IconButton>
        <Typography
          variant="title"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <LoadingIndicator />
        {cloneElement(entityMgr && entityMgr ? <CustomUserMenu /> : <React.Fragment/>, { logout })}
      </Toolbar>
    </MuiAppBar>
  </Headroom>
);


AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(customStyles),
  withWidth()
);

export default enhance(AppBar);
