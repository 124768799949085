import React from 'react';
import { Route } from 'react-router-dom';

import ForgotPassword from './external/ForgotPassword';
import ResetPassword from './external/ResetPassword';
import CreateAccount from './external/CreateAccount';

import Account from './internal/account'


export const externalCustomRoutes = [
  <Route exact path="/forgot_password" component={ForgotPassword} />,
  <Route exact path="/reset_password/:token" component={ResetPassword} />,
  <Route exact path="/create_account/:token" component={CreateAccount} />,
];

export const internalCustomRoutes = [
  <Route exact path="/account" component={Account} />,
];
