import './index.css';
import React from 'react';
import moment from 'moment';

import {
  formatMoney,
  getFromNestedSafely as gS
} from './../../services';

const today = new Date();
const defaultImg = '//cloudfront.startupmedia.com/greenback/misc/graphics/upload-face-preview.png';

const UnitPreview = (props) => {

  //safe getter for props
  const pgs = gS.bind(null, props);

  // Generics
  const type = pgs('subentity.type');
  const goal = pgs(`subentity.${props.source}.fundraising.goal`);
  const entityLogo = pgs('entityMgr.entity.cause.is_sponsor') ? pgs('entityMgr.entity.cause.sponsors_of', [])[0] : pgs('entityMgr.entity_id');
  const selfLogo = pgs(`subentity.${props.source}.img`, defaultImg);
  const amount_raised = pgs('subentity.earnings') ? pgs('subentity.earnings') : '0'

  let name, signature, short_desc, long_desc, parentLogo, end_date;

  if (type === 'unit') {
    name = pgs(`subentity.${props.source}.name`);
    signature = pgs(`subentity.${props.source}.signature`);
    short_desc = pgs(`subentity.${props.source}.fundraising.short_description`);
    long_desc = pgs(`subentity.${props.source}.fundraising.long_description`);
    end_date = pgs(`subentity.${props.source}.fundraising.end_date`);

  }
  else if (type === 'subunit' && pgs('entityMgr.subentity.type') === 'unit') {
    name = pgs(`entityMgr.subentity.${props.source}.name`);
    signature = pgs(`subentity.${props.source}.name`);
    short_desc = pgs(`entityMgr.subentity.${props.source}.fundraising.short_description`);
    long_desc = pgs(`entityMgr.subentity.${props.source}.fundraising.long_description`);
    parentLogo = pgs(`entityMgr.subentity.${props.source}.img`, defaultImg);
    end_date = pgs(`entityMgr.subentity.${props.source}.fundraising.end_date`);
  }
  else if (type === 'subunit' && pgs('entityMgr.subentity.type') === 'subunit') {
    name = pgs(`entityMgr.subentity.subentity.${props.source}.name`);
    signature = pgs(`subentity.${props.source}.name`);
    short_desc = pgs(`entityMgr.subentity.subentity.${props.source}.fundraising.short_description`);
    long_desc = pgs(`entityMgr.subentity.subentity.${props.source}.fundraising.long_description`);
    parentLogo = pgs(`entityMgr.subentity.subentity.${props.source}.img`, defaultImg);
    end_date = pgs(`entityMgr.subentity.subentity.${props.source}.fundraising.end_date`);
  }

  end_date = moment(end_date);
  const days_left = end_date.diff(today, 'days');
  end_date = end_date.format('MMMM Do');

  const styles = {
    bar: {
      width: `${(amount_raised / goal) * 100}%`,
      borderRadius: '20px'
    },
    selfLogo: {
      backgroundImage: `url("${selfLogo}")`
    },
    entityLogo: {
      backgroundImage: `url("//cloudfront.startupmedia.com/greenback/entities/${entityLogo}/logo.png")`
    },
    parentLogo: {
      backgroundImage: `url("${parentLogo}")`
    },
    center: {
      textAlign: 'center'
    },
    cardBox: {
      textAlign: 'center',
      fontSize: '14px'
    }
  };

  return (
    <div className="fundraising">
      <div className="header__wrapper">
        <div className="header">
          <div className="header__img" style={styles.selfLogo}>
            {type === 'unit' ? '' : <div className="header__img__secondary" style={styles.parentLogo}></div> }
          </div>
          <div className="header__pitch">
            <h1>Help support <b>{name}</b> by donating below, and as a thank you we're giving you greenback!</h1>
            <h3>- {signature ? signature : 'Header Message Signature (optional)'}</h3>
          </div>
        </div>
      </div>
      <div className="donation">
        <div className="donation__btns">
          <h2>
            <span>Donate and get &nbsp;
              <span className="gbLogo">
                <span>green</span>back<sup>®</sup>
              </span>
            </span>
          </h2>
          <div className="global__btn btn">
            Donate Now!
          </div>
          <div className="blue__btn btn">Share With Friends</div>
        </div>
        <div className="donation__details">
          <h2>
            <span>Help me reach my goal by {end_date}!</span>
          </h2>
          <div className="donation__bar">
            <div className="donation__bar--progress" style={styles.bar}></div>
          </div>
          <div className="donation__amounts">
            <div>
              <span className="green">${amount_raised}</span>
              &nbsp;raised of my&nbsp;
              <span className="blue">${formatMoney(goal)} </span>
              goal
            </div>
            <div className="daysLeft">{days_left >= 0 ? days_left : '0'} days left</div>
          </div>
        </div>
      </div>

      <div className="details">
        <div className="details__wrapper">
          <div className="details__col w66">
            <div className="details__row">
              <div className="details__card">
                <div className="details__card__title"></div>
                <div className="details__card__box h100" style={styles.center}>
                  <h2>
                    <span>Save more than you donate
                      with&nbsp;
                      <span className="gbLogo">
                        <span>green</span>back<sup>®</sup>
                      </span>!</span>
                  </h2>
                  <h4 style={{margin: 0}}>Access over 350,000 discounts nationwide with the&nbsp;
                    <span className="gbLogo">
                      <span>green</span>back<sup>®</sup>
                    </span>
                    &nbsp;everyday savings program and mobile app.</h4>
                  <br/>
                  <img src="//cloudfront.startupmedia.com/greenback/devices/grouped-phones-app-tour.png"/>
                  <p>Learn more about&nbsp;
                    <span className="gbLogo">
                      <span>green</span>back<sup>®</sup>
                    </span>
                    &nbsp;here.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="details__col">
            <div className="details__row">
              <div className="details__card">
                <div className="details__card__title">How Donations Benefit Us</div>
                <div className="details__card__box">
                  <p>{short_desc}</p>
                </div>
              </div>
              <div className="details__card w66">
                <div className="details__card__title">How We Receive the Donation</div>
                <div className="details__card__box" style={styles.cardBox}>
                  <div className="details__card__box--img" style={styles.entityLogo}></div>
                  <br/>
                  <p>Your donation is made out to your Cause, which provides us with your funds.</p>
                </div>
              </div>
            </div>
            <div className="details__row">
              <div className="details__card">
                <div className="details__card__title">Special Message</div>
                <div className="details__card__box">
                  <p>{long_desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="details__footer">
          <p>All donations made on this page go to benefit the above fundraiser and a greenback membership will be provided to you as a thank you.</p>
        </div>
      </div>
    </div>
  );
}

UnitPreview.defaultProps = {
  source: 'templating'
};

export default UnitPreview;
