import React, {Component} from 'react';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';

import {
    getFromNestedSafely as gS
} from './../../services';

const styles = {
  root: {
    fontSize: 14,
    fontFamily: "'ProximaNova-Light'",
  },
  label: {
    top: 38,
    color: '#666',
    fontFamily: "'ProximaNova-Light'",
    fontSize: 14
  },
  input: {
    marginTop: 14,
    fontFamily: "'ProximaNova-Light'",
    fontSize: 14
  },
  error: {
    lineHeight: 'inhert',
    textAlign: 'left',
    fontSize: 10,
  }
}

export class DateInput extends Component {

  constructor(props) {
    super(props);
    this.dateChange = this.dateChange.bind(this);
  }

  dateChange(event, date) {
    this.props.change(this.props.input.name, date && date.toISOString());
  }

  render() {
    const endDate = gS(this.props, 'record.templating.fundraising.end_date');
    let date = ""
    if (endDate) {
      date = new Date(endDate);
    }

    return (
      <TextField
        {...this.props}
        fullWidth
        style={styles.root}
        floatingLabelStyle={styles.label}
        inputStyle={styles.input}
        errorStyle={styles.error}
        locale="en-US"
        onChange={this.dateChange}
        value={date}
        type="date"
      />
    )
  }
}

export default DateInput
