import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
  loadImage,
  uploadThumbor,
  formatImage,
  dataURItoBlob,
} from './../../config/thumbor';

import {
  setNestedSafely as sS,
  getFromNestedSafely as gS
} from './../../services';

const styles = {
	wrapper: {
		overflow: 'hidden',
		marginBottom: 10
	},
	label: {
		position: 'relative',
		fontSize: 14,
		color: '#666',
		transform: 'scale(0.75)',
		transformOrigin: 'top left',
		marginBottom: 5
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	image: {
		position: 'relative'
	},
	defaultPreview: {
		position: 'relative',
		width: 100,
		height: 100,
		borderRadius: '50%',
		border: '1px solid #9B9B9B',
		background: 'url(//cloudfront.startupmedia.com/greenback/misc/graphics/upload-face-preview.png) no-repeat center',
		backgroundSize: 'contain',
		zIndex: 0
	},
	sample: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: 100,
		height: 100,
		border: '1px solid #9B9B9B',
		zIndex: 250,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		borderRadius: '50%'
	},
	uploadPreview: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: 100,
		height: 100,
		opacity: 0,
		border: 0,
		cursor: 'pointer',
		zIndex: 500,
		backgroundImage: ''
	},
	btn: {
		position: 'relative',
		width: 125,
		height: 44,
		lineHeight: 44,
		border: '1px solid #2CBBB7',
		borderRadius: 3,
		color: '#2CBBB7',
		fontSize: 16,
		margin: '0 0 0 20px',
		cursor: 'pointer',
		background: '#FFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		height: 20,
		width: 'auto',
		display: 'inline-block',
		margin: '0 10px 0 0'
	},
	hidden: {
		position: 'absolute',
		top: -2000,
		left: -2000,
		opacity: 0,
		display: 'none'
	},
	error: {
		position: 'relative',
		marginTop: 5,
		fontSize: 12,
		lineHeight: '12px',
		color: 'rgb(244, 67, 54)'
	},
  editor: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',
    zIndex: 1000,
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editorWrapper: {
    position: 'relative',
    width: '80%',
    maxWidth: 600,
    padding: 20,
    background: '#FFF',
  },
  cropper: {
    maxWidth: 600,
    maxHeight: 600,
    width: '100%',
    height: '70vh'
  },
  editorLoading: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
    height: '100%',
		zIndex: 900,
    background: 'rgba(255,255,255,0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	},
  crop: {
    position: 'relative',
		width: 75,
		height: 30,
		border: '1px solid #2CBBB7',
		borderRadius: 3,
		color: '#2CBBB7',
		fontSize: 12,
		marginLeft: 'auto',
		cursor: 'pointer',
		background: '#FFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
  },
  editorBtn: {
    position: 'relative',
		height: 30,
		border: '1px solid #2CBBB7',
		borderRadius: 3,
		color: '#2CBBB7',
		fontSize: 12,
    marginRight: 10,
		cursor: 'pointer',
		background: '#FFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
  },
  btnGroup: {
    display: 'flex',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  editorBtns: {
    display: 'flex',
  },
  closeEditor: {
    position: 'absolute',
    color: 'red',
    height: 30,
    width: 30,
    borderRadius: '100%',
    background: '#fff',
    top: -10,
    right: -10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    cursor: 'pointer',
  }
};

export class ImageInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      rotation: 0,
      uploadedFile: null,
      loading: false,
      editor: false,
      image: null,
      editorLoading: false,
    }
    this.getImgForCropping = this.getImgForCropping.bind(this);
    this.uploadCroppedImg = this.uploadCroppedImg.bind(this);
    this.rotateLeft = this.rotateLeft.bind(this);
    this.rotateRight = this.rotateRight.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    // this.edit = this.edit.bind(this);
    this.closeEditor = this.closeEditor.bind(this);
    // delete this?
    // this.imgUpload = this.imgUpload.bind(this);
  }

  getValue() {
    return gS(this.props.record, this.props.source);
  }

  setValue(value) {
    sS(this.props.record, this.props.source, value);
    this.setState({record: this.props.record});
  }

  // imgUpload(e) {
  //   e.preventDefault();
  //   // console.log(e.target.files)
  //   if (e.target.files.length === 1) {
  //     let file = e.target.files[0]
  //     loadImage(e.target.files[0])
  //     .then(uploadThumbor)
  //     .then(formatImage)
  //     .then(({src, dataUri}) => {
  //       this.setValue(src);;
  //       // reset error
  //       this.setState({error: null});
  //     })
  //     .catch(err => {
  //       console.log('error', err)
  //       this.setState({error: err.message})
  //     })
  //   }
  // }

  closeEditor(e) {
    e.preventDefault();
    this.setState({editor: false, loading: false})
  }

  getImgForCropping(e) {
    e.preventDefault();
    let file;
    if (e.target && e.target.files && e.target.files.length === 1) {
      file = e.target.files[0];
      this.setState({uploadedFile: file});
    }
    else {
      file = this.state.uploadedFile;
    }

    if (!file) return;

    this.setState({loading: true});

    loadImage({file: file})
    .then(file => {
      this.setState({
        editor: true,
        image: file.dataUri,
        error: null
      })
    })
    .catch(err => {
      console.log('error', err)
      this.setState({error: err.message, loading: false, editorLoading: false})
    })
  }

  uploadCroppedImg(e) {
    e.preventDefault();
    let dataUri = this.cropper.getCroppedCanvas().toDataURL();
    let file = dataURItoBlob(dataUri);
    this.setState({error: null, editorLoading: true});
    uploadThumbor({file, dataUri})
    .then(formatImage)
    .then(({src, dataUri}) => {
      this.setValue(src);
      // reset error
      this.setState({error: null, loading: false, editor: false, editorLoading: false});
    })
    .catch(err => {
      console.log('error', err)
      this.setState({error: err.message, loading: false, editorLoading: false})
    })
  }

  rotateLeft(e) {
    e.preventDefault();
    this.cropper.rotate(-90);
  }

  rotateRight(e) {
    e.preventDefault();
    this.cropper.rotate(90);
  }

  zoomIn(e) {
    e.preventDefault();
    this.cropper.zoom(0.1);
  }

  zoomOut(e) {
    e.preventDefault();
    this.cropper.zoom(-0.1);
  }


  render() {

    const inputValue = this.getValue();

    const stylesSample = {
      backgroundImage: `url("${inputValue}")`
    };

    return (
      <div style={styles.wrapper}>

        {this.state.editor ? (
          <div style={styles.editor}>
            <div style={styles.editorWrapper}>
              <div style={styles.closeEditor} onClick={this.closeEditor}>x</div>
              {this.state.editorLoading && (<div style={styles.editorLoading}><CircularProgress size={200} thickness={10}/></div>)}
              <Cropper
                ref={cropper => { this.cropper = cropper; }}
                src={this.state.image}
                style={styles.cropper}
                aspectRatio={1 / 1}
                guides={true}
              />
              <div style={styles.btnGroup}>
                <div style={styles.editorBtns}>
                  <button style={styles.editorBtn} onClick={this.rotateLeft}>&#10226; Rotate</button>
                  <button style={styles.editorBtn} onClick={this.rotateRight}>&#10227; Rotate</button>
                  <button style={styles.editorBtn} onClick={this.zoomIn}>Zoom In</button>
                  <button style={styles.editorBtn} onClick={this.zoomOut}>Zoom Out</button>
                </div>
                <button style={styles.crop} onClick={this.uploadCroppedImg}>Save</button>
              </div>
            </div>
          </div>
        ) : ''}

        <div style={styles.label}>{this.props.label}*</div>
        <div style={styles.container}>
          <div style={styles.image}>
            <div style={styles.defaultPreview}></div>
              <div style={{...styles.sample, ...stylesSample}}></div>
            {this.props.disabled ? (<div style={styles.sampleDisabled}></div>) : ''}
            <input type="file" style={styles.uploadPreview} accept="image/*" onChange={this.getImgForCropping} disabled={this.props.disabled}/>
            {this.state.loading && (<div style={styles.loading}><CircularProgress /></div>)}
          </div>
          <div>
            <label htmlFor="imageUpload" style={this.props.disabled ? ({...styles.btn, ...styles.disabled}) : styles.btn}><img style={styles.icon} src="//cloudfront.startupmedia.com/greenback/misc/graphics/upload-cloud-green.png" /> Upload
            <input id="imageUpload" name="imageUpload" type="file" style={styles.hidden} accept="image/*" disabled={this.props.disabled} onChange={this.getImgForCropping} />
            </label>

          </div>

        </div>
      </div>
    )
  }
}


export default ImageInput;
