import React from 'react';
import { connect } from 'react-redux';
import {
    BooleanField,
		BulkActions,
    Datagrid,
    Filter,
    List,
    NullableBooleanInput,
    NumberField,
    TextField,
    TextInput,
} from 'react-admin';

import BulkAction from './../../components/Approval/BulkAction';
import { EditButton } from '../../components/ListButtons';

import {
  getFromNestedSafely as gS,
  entityMgrConnect
} from './../../services'


const SubentityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="where.id.like" alwaysOn />
    <NullableBooleanInput source="where.is_active" label="Is Active" />
  </Filter>
);

const UnitBulkActions = props => (
  <BulkActions {...props}>
    <BulkAction label="Activate" payloadData={{status: "activate"}}/>
    <BulkAction label="Deactivate" payloadData={{status: "deactivate"}}/>
  </BulkActions>
);

const needAcitvationStyle = (record, index) => ({
    borderLeft: record.is_active ? '10px solid #fff' : '10px solid rgba(218,59,22, 0.5)',
});

export const UnitListComponent = (props, record) => {
  return (
    <div>
      <List {...props}
        filters={<SubentityFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={10}
        title="Fundraisers"
        bulkActions={<UnitBulkActions />}>
          <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }} rowStyle={needAcitvationStyle}>
            <TextField source="id" />
            <TextField source="templating.name" label="Name" />
            <NumberField source="invitations_sent" label="Invitations Sent" />
            <NumberField source="earnings" options={{ style: 'currency', currency: 'USD' }} label="Funds Raised"/>
            <BooleanField source="is_active" />
            <EditButton />
          </Datagrid>
      </List>
    </div>
  )
}

export const UnitList = entityMgrConnect(UnitListComponent);
