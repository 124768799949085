import { put, takeEvery, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  showNotification,
  hideNotification,
} from './../actions/customNotification';

import {actions as toastrActions} from 'react-redux-toastr'

import config from './../config/config.provider';

const defaultToastrOptions = {
	"showCloseButton": true,
	"debug": false,
	"newestOnTop": true,
	"progressBar": false,
	"positionClass": "toast-top-right",
	"preventDuplicates": false,
	"onclick": null,
	"showDuration": "300",
	"hideDuration": "1000",
	"timeOut": "5000",
	"extendedTimeOut": "1000",
	"showEasing": "swing",
	"hideEasing": "linear",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut"
};

const getTitleAndMessageFromAction = (action) => {
  const eventShortName = `${action.meta.resource}_${action.meta.fetchResponse}_${action.meta.basePath}`;
  console.log(eventShortName);
  const response = config.notification_saga_responses[eventShortName];
  return response || {};
}
/**
Example:
		meta:
			cancelPrevious: false
			fetchResponse: "CREATE"
			fetchStatus: "AOR/FETCH_END"
			resource: "subunits"
			__proto__: Object
		payload:
			data: {entity_manager_id: 509, entity_id: 77, subentity_id: 416, entity_manager: {…}, coppa_acknowledged: true, …}
			__proto__: Object
		requestPayload:
			basePath: "/subunits"
			data: {entity_manager_id: 509, entity_id: 77, subentity_id: 416, entity_manager: {…}, coppa_acknowledged: true}
			filter: {type: "subunit"}
			redirectTo: "/subunits"
			__proto__: Object
		type: "AOR/CRUD_CREATE_SUCCESS"
		@@redux-saga/SAGA_ACTION: true
*/
function* onCrudCreateSuccess(action) {
  console.log(action);
  const { title, message } = getTitleAndMessageFromAction(action);
	const toastr = {
		type: 'success',
    title,
    message: message || "Success.",
		options: defaultToastrOptions,
    ...defaultToastrOptions
	}; 
  yield put(toastrActions.add(toastr));
}


/**
Example:
		error: "Email address already exists."
		meta:
			cancelPrevious: false
			fetchResponse: "CREATE"
			fetchStatus: "AOR/FETCH_ERROR"
			resource: "subunits"
			__proto__: Object
		payload: null
		requestPayload:
			basePath: "/subunits"
			data: {entity_manager_id: 509, entity_id: 77, subentity_id: 416, entity_manager: {…}, coppa_acknowledged: true}
			filter: {type: "subunit"}
			redirectTo: "/subunits"
			__proto__: Object
		type: "AOR/CRUD_CREATE_FAILURE"
		@@redux-saga/SAGA_ACTION: true
*/
function* onCrudCreateFailure(action) {
  console.log(action);
	const toastr = {
		type: 'error',
		//title: action.payload.type,
		message: action.error,
		options: defaultToastrOptions,
    ...defaultToastrOptions
	}; 

  //here we can handle specific message using configuration keys based 
  //on the message response and/or basePath
  yield put(toastrActions.add(toastr));
}

//messages in this array will not trigger a toastr notification
const filterableNotificationMessages = [
  "ra.notification.updated",
  "ra.notification.created",
	"ra.notification.deleted",
  "ra.notification.bad_item",
  "ra.notification.item_doesnt_exist",
  "ra.notification.http_error"
];

function* onShowAorNotification(action) {
  if (filterableNotificationMessages.includes(action.payload.message)) return;
  console.log(action);
	const toastr = {
		type: action.payload.type,
		title: action.payload.type,
		message: action.payload.message,
		options: defaultToastrOptions,
    ...defaultToastrOptions
	}; 
  //here we can handle specific message using configuration keys based 
  //on the message response and/or basePath
  yield put(toastrActions.add(toastr));
}

function* onShowNotification(action) {
  console.log(action);
	const toastr = {
		type: action.payload.type,
		title: action.payload.title,
		message: action.payload.message,
		options: defaultToastrOptions,
    ...defaultToastrOptions
	}; 
  //here we can handle specific message using configuration keys based 
  //on the message response and/or basePath
  yield put(toastrActions.add(toastr));
}

export default function* customNotificationSaga() {
  yield all([
      takeEvery('RA/CRUD_CREATE_FAILURE', onCrudCreateFailure),
      takeEvery('RA/CRUD_CREATE_SUCCESS', onCrudCreateSuccess),
      takeEvery('RA/CRUD_UPDATE_SUCCESS', onCrudCreateSuccess),
      takeEvery('RA/SHOW_NOTIFICATION', onShowAorNotification),
      takeEvery(SHOW_NOTIFICATION, onShowNotification)
	]);
}
