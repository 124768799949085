import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    ImageField,
    TextField,
    NumberField,
    DateField,
    TextInput,
    NumberInput,
    DateInput,
    LongTextInput,
    ReferenceManyField,
    Datagrid
} from 'react-admin';
import {
  ImageInput
} from './../../components';
import { entityMgrConnect } from './../../services'

const style = theme => ({
  field: {
    width: '100%',
    background: 'red'
  }
});

const UnitEditToolbar = props => {
  console.log('props', props)
  return (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  )
};

const UnitEditComponent = (props) => (
  <Edit title="Fundraisers" {...props} undoable={false}>
    <TabbedForm  redirect="edit" toolbar={<UnitEditToolbar />}>
      <FormTab label="Published Fundraiser">
        <ImageField source="templating.img" label="Organization Image" />
        <TextField source="templating.name" label="Title"/>
        <TextField source="templating.signature" label="Signature"/>
        <NumberField source="templating.fundraising.goal" options={{ style: 'currency', currency: 'USD' }} label="Fundraiser Goal"/>
        <DateField source="templating.fundraising.end_date" label="End Date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
        <TextField source="templating.fundraising.short_description" label="How donations are used"/>
        <TextField source="templating.fundraising.long_description" label="Special Message"/>
      </FormTab>

      <FormTab label="Pending Changes">
        <br />
        <ImageInput label="Organization Image" source="templating.img" />
        <TextInput source="templating.name" label="Title"/>
        <TextInput source="templating.signature" label="Signature"/>
        <NumberInput source="templating.fundraising.goal" label="Fundraiser Goal"/>
        <DateInput source="templating.fundraising.end_date" label="End Date" />
        <LongTextInput source="templating.fundraising.short_description" label="How donations are used"/>
        <LongTextInput source="templating.fundraising.long_description" label="Special Message"/>
      </FormTab>

      <FormTab label="Boosters">

      </FormTab>

      <FormTab label="Contact Details">
        <TextField source="entity_manager.first_name" label="First Name"/>
        <TextField source="entity_manager.last_name" label="Last Name"/>
        <TextField source="entity_manager.email" type="email" label="Email"/>
        <TextField source="entity_manager.phone_number" type="tel" label="Phone Number"/>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const UnitEdit = withStyles(style)(UnitEditComponent);
