import aorRestClient from './index';
import {
		WITH_ROUTE,
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from './types';

export const CRUD_GET_LIST = GET_LIST;
export const CRUD_GET_LIST_LOADING = 'GET_LIST_LOADING';
export const CRUD_GET_LIST_FAILURE = 'GET_LIST_FAILURE';
export const CRUD_GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';

export const crudGetList = (
    resource,
    pagination,
    sort,
    filter,
    cancelPrevious = true
) => aorRestClient({
    type: CRUD_GET_LIST,
    payload: { pagination, sort, filter },
    meta: { resource, fetch: GET_LIST, cancelPrevious },
});

export const CRUD_GET_ONE = GET_ONE;
export const CRUD_GET_ONE_LOADING = 'GET_ONE_LOADING';
export const CRUD_GET_ONE_FAILURE = 'GET_ONE_FAILURE';
export const CRUD_GET_ONE_SUCCESS = 'GET_ONE_SUCCESS';

export const crudGetOne = (
    resource, 
    id, 
    basePath, 
    cancelPrevious = true
) => aorRestClient({
    type: CRUD_GET_ONE,
    payload: { id, basePath },
    meta: { resource, fetch: GET_ONE, cancelPrevious },
});

export const CRUD_CREATE = CREATE;
export const CRUD_CREATE_LOADING = 'CREATE_LOADING';
export const CRUD_CREATE_FAILURE = 'CREATE_FAILURE';
export const CRUD_CREATE_SUCCESS = 'CREATE_SUCCESS';

export const crudCreate = (
    resource, 
    data, 
    basePath, 
    redirectTo = 'edit'
) => aorRestClient({
    type: CRUD_CREATE,
    payload: { data, basePath, redirectTo },
    meta: { resource, fetch: CREATE, cancelPrevious: false },
});

export const CRUD_UPDATE = UPDATE;
export const CRUD_UPDATE_LOADING = 'UPDATE_LOADING';
export const CRUD_UPDATE_FAILURE = 'UPDATE_FAILURE';
export const CRUD_UPDATE_SUCCESS = 'UPDATE_SUCCESS';

export const crudUpdate = (
    resource,
    id,
    data,
    previousData,
    basePath,
    redirectTo = 'show'
) => aorRestClient({
    type: CRUD_UPDATE,
    payload: { id, data, previousData, basePath, redirectTo },
    meta: { resource, fetch: UPDATE, cancelPrevious: false },
});

export const CRUD_UPDATE_MANY = UPDATE_MANY;
export const CRUD_UPDATE_MANY_LOADING = 'UPDATE_MANY_LOADING';
export const CRUD_UPDATE_MANY_FAILURE = 'UPDATE_MANY_FAILURE';
export const CRUD_UPDATE_MANY_SUCCESS = 'UPDATE_MANY_SUCCESS';

export const crudUpdateMany = (
    resource,
    id,
    data,
    previousData,
    basePath,
    redirectTo = 'list'
) => aorRestClient({
    type: CRUD_UPDATE_MANY,
    payload: { id, data, previousData, basePath, redirectTo },
    meta: { resource, fetch: UPDATE_MANY, cancelPrevious: false },
});

export const CRUD_DELETE = DELETE;
export const CRUD_DELETE_LOADING = 'DELETE_LOADING';
export const CRUD_DELETE_FAILURE = 'DELETE_FAILURE';
export const CRUD_DELETE_SUCCESS = 'DELETE_SUCCESS';

export const crudDelete = (
    resource,
    id,
    previousData,
    basePath,
    redirectTo = 'list'
) => aorRestClient({
    type: CRUD_DELETE,
    payload: { id, previousData, basePath, redirectTo },
    meta: { resource, fetch: DELETE, cancelPrevious: false },
});

export const CRUD_GET_MANY = GET_MANY;
export const CRUD_GET_MANY_LOADING = 'GET_MANY_LOADING';
export const CRUD_GET_MANY_FAILURE = 'GET_MANY_FAILURE';
export const CRUD_GET_MANY_SUCCESS = 'GET_MANY_SUCCESS';

// Reference related actions

export const crudGetMany = (
    resource, 
    ids
) => aorRestClient({
    type: CRUD_GET_MANY,
    payload: { ids },
    meta: { resource, fetch: GET_MANY, cancelPrevious: false },
});

export const CRUD_GET_MATCHING = 'GET_MATCHING';
export const CRUD_GET_MATCHING_LOADING = 'GET_MATCHING_LOADING';
export const CRUD_GET_MATCHING_FAILURE = 'GET_MATCHING_FAILURE';
export const CRUD_GET_MATCHING_SUCCESS = 'GET_MATCHING_SUCCESS';

export const crudGetMatching = (
    reference,
    relatedTo,
    pagination,
    sort,
    filter
) => aorRestClient({
    type: CRUD_GET_MATCHING,
    payload: { pagination, sort, filter },
    meta: {
        resource: reference,
        relatedTo,
        fetch: GET_LIST,
        cancelPrevious: false,
    },
});

export const CRUD_GET_MANY_REFERENCE = GET_MANY_REFERENCE;
export const CRUD_GET_MANY_REFERENCE_LOADING =
    'GET_MANY_REFERENCE_LOADING';
export const CRUD_GET_MANY_REFERENCE_FAILURE =
    'GET_MANY_REFERENCE_FAILURE';
export const CRUD_GET_MANY_REFERENCE_SUCCESS =
    'GET_MANY_REFERENCE_SUCCESS';

export const crudGetManyReference = (
    reference,
    target,
    id,
    relatedTo,
    pagination,
    sort,
    filter
) => aorRestClient({
    type: CRUD_GET_MANY_REFERENCE,
    payload: { target, id, pagination, sort, filter },
    meta: {
        resource: reference,
        relatedTo,
        fetch: GET_MANY_REFERENCE,
        cancelPrevious: false,
    },
});
