import React from 'react';
import { connect } from 'react-redux';
import {
  BooleanField,
  BulkActions,
  Datagrid,
  Filter,
  List,
  NullableBooleanInput,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import BulkAction from './../../components/Approval/BulkAction';
import { EditButton } from '../../components/ListButtons';
import { ListProfileImg } from '../../components';
import {
  getFromNestedSafely as gS,
  entityMgrConnect
} from './../../services'

const SubentityFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="where.id" alwaysOn />
        <NullableBooleanInput source="where.is_active" label="Is Active" />
    </Filter>
);

const UnitBulkActions = props => (
    <BulkActions {...props}>
        <BulkAction label="Activate" payloadData={{status: "activate"}}/>
        <BulkAction label="Deactivate" payloadData={{status: "deactivate"}}/>
    </BulkActions>
);

const needActivationStyle = (record, index) => ({
    borderLeft: record.is_active ? '10px solid #fff' : '10px solid rgba(218,59,22, 0.5)',
});

export const SubunitListComponent = (props, record) => {
  return (
    <div>
      <List {...props}
        filters={<SubentityFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={25}
        title=""
        bulkActionButtons={<UnitBulkActions />}>
          <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }} rowStyle={needActivationStyle}>
            <TextField source="id" />
            <ListProfileImg source="templating.img" label=""/>
            <TextField source="templating.name" label="Name" />
            <TextField source="subentity_id" label="Fundraiser ID" />
            <BooleanField source="is_active" />
            <EditButton />
          </Datagrid>
      </List>
    </div>
  )
}

export const SubunitList = entityMgrConnect(SubunitListComponent);
