import React from 'react';

const InnerTitle = (props) => {
  const styles = {
    style: {
      backgroundColor: '#FFF',
      boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
      marginBottom: 10,
      padding: 20,
      fontSize: props.fontSize,
      fontFamily: 'ProximaNova-SemiBold',
      textAlign: props.alignment
    }
  }
  return (
    <div style={styles.style}>{props.title}</div>
  )
}

export default InnerTitle;
