import {queryParameters, fetchJson} from './fetch';
import {
    WITH_ROUTE,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
} from './types';
export * from './authClient';

//used to merge params
function MergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor==Object ) {
        obj1[p] = MergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch(e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

/**
 * Maps admin-on-rest queries to a loopback powered REST API
 *
 * @see https://github.com/strongloop/loopback
 * @example
 * GET_LIST     => GET http://my.api.url/posts?filter[sort]="title ASC"&filter[skip]=0&filter[limit]=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter[where][or]=[{id:123},{id:456}]
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (config, httpClient = fetchJson) => {

    const likify = (query) => {
      if(query.where) {
        for (var prop in query.where) {
          if(query.where[prop] && query.where[prop].like) {
            query.where[prop].ilike = `%${query.where[prop].like}%`;
            delete query.where[prop].like;
          }
        }
      }
    }
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        resource = resource.toLowerCase();
        let url = '';
        const options = {};
        const apiName = config.resourceApiRouting[resource];
        if  (!apiName) {
          throw new Error(`Must provice an api name for the resource: ${resource}`);
        }
        const apiUrl = config.apiEndpoints[apiName];

        if (config.extendedResources[resource]) {
          const resourceParams = config.extendedResources[resource].params;
          resource = config.extendedResources[resource].route;
          params = MergeRecursive(params, resourceParams);
        }

        if (!apiUrl) {
          throw new Error(`Must provide an apiEndpoint url for ${apiName}.${resource}`);
        }
        switch (type) {
            case WITH_ROUTE:
                url = `${apiUrl}/${resource}`;
                if (params.route)
                  url += `/${params.route}`;
                if (params.token)
                  url += `?access_token=${params.token}`;
                if (params.method)
                  options.method = params.method;
                if (params.data && params.method && params.method !== "GET") {
                  options.body = JSON.stringify(params.data);
                }
                else if (params.data) {
                  url += `?${queryParameters(params.data)}`;
                  console.log(url);
                }
                break;
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {};
                if (params.filter) {
                  if (params.filter.where) {
                    query.where = JSON.parse(JSON.stringify(params.filter.where));
                  }
                  if (params.filter.include) {
                    query.include = JSON.parse(JSON.stringify(params.filter.include))
                  }
                }
                likify(query);
                if (field) query['order'] = [field + ' ' + order];
                if (perPage > 0) {
                    query['limit'] = perPage;
                    if (page >= 0) {
                        query['skip'] = (page - 1) * perPage;
                    }
                }
                delete params.filter;
                delete params.pagination;
                delete params.sort;
                url = `${apiUrl}/${resource}?${queryParameters({filter: JSON.stringify(query), ...params})}`;
                break;
            }
            case GET_ONE:
                if (!params.id) throw new Error('params id is required');
                url = `${apiUrl}/${resource}/${params.id}`;
                if (params.filter) {
                  const query = JSON.parse(JSON.stringify(params.filter));
                  delete params.filter;
                  delete params.basePath;
                  delete params.id;
                  url = `${url}?${queryParameters({filter: JSON.stringify(query), ...params})}`;
                }
                break;
            case GET_MANY: {
                const listId = params.ids.map(id => {
                    return {'id': id};
                });
                const query = {
                    'where': {'or': listId}
                };
                url = `${apiUrl}/${resource}?${queryParameters({filter: JSON.stringify(query)})}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {where: params.filter && JSON.parse(JSON.stringify(params.filter.where))};
                likify(query);
                query['where'][params.target] = params.id;
                if (field) query['order'] = [field + ' ' + order];
                if (perPage > 0) {
                  query['limit'] = perPage;
                  if (page >= 0) {
                    query['skip'] = (page - 1) * perPage;
                  }
                }
                url = `${apiUrl}/${resource}?${queryParameters({filter: JSON.stringify(query)})}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PATCH';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const {headers, json} = response;
        switch (type) {
            case WITH_ROUTE:
              return {
                response
              };
              break;
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers.has('x-total-count')) {
                    throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
                }
                return {
                    data: json,
                    total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
                };
            case CREATE:
                return { response, data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        const {url, options} = convertRESTRequestToHTTP(type, resource, params);
        return httpClient(url, options)
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
    };
};
