import React, {createElement, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { themeService } from './../../services';
import ReduxToastr from 'react-redux-toastr'
import AppBar from './AppBar';
import { styles } from './../../routes/external/shared';
import {
  AppFooter,
} from './../../components';

const ExternalLayout = ({children, isLoading, title}) => {
  return (
    <MuiThemeProvider>
      <div style={styles.main}>
        <AppBar title={title || "Management Portal"} entityMgr={false} hamburger="false"/>
        <div style={styles.card}>
          <div style={styles.content}>
            {children}
          </div>
        </div>
        <AppFooter />
        <ReduxToastr />
        {isLoading && (<CircularProgress color="#fff" size={30} thickness={2} style={styles.loader}/>)}
      </div>
    </MuiThemeProvider>
  );
}

ExternalLayout.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

export default connect(mapStateToProps)(ExternalLayout);
