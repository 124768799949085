import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  EditAccountForm,
  GreenButton,
  InnerTitle,
  AccountChangePassword
} from './../../../components';

import {
  entityMgrConnect
} from './../../../services';

const styles = {
  wrapper: {
    padding: 0,
    margin: 0,
    height: '100%'
  },
  container: {
    height: '100%',
    width: '100%',
    fontFamily: 'ProximaNova-Light',
    color: '#464D5E',
    position: 'relative'
  },
  content: {
    backgroundColor: '#FFF',
    flexShrink: 0,
    padding: '30px',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    position: 'relative',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  col: {
    width: '45%',
    // outline: '1px solid red'
  },
  spacer: {
    width: 1,
    height: 250,
    background: '#D5D5D5',
    margin: '30px',
    alignSelf: 'center'
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    margin: '0 0 30px 0',
    padding: 0
  },
}

const Account = (props) => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <InnerTitle title="General Account Info" fontSize={24} alignment="left" />
        <div style={styles.content}>
          <div style={styles.col}>
            <p style={styles.header}>Main Contact Details</p>
            <EditAccountForm entityMgr={props.entityMgr} />
          </div>
          <div style={styles.spacer} />
          <div style={styles.col}>
            <p style={styles.header}>Change Password</p>
            <AccountChangePassword />
          </div>
        </div>
      </div>
    </div>
  )
}

export default entityMgrConnect(Account);
