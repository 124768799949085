import { put, takeEvery, all,  select} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { 
	fetchEntitymanagerBegin,
	fetchEntitymanagerSuccess,
	fetchEntitymanagerFailure,
  fetchEntitymanagerById,
} from './../actions/entityManager';
import storage from './../aor-loopback/storage';

function* onUserLoginLoading() {
	yield put(fetchEntitymanagerBegin());
}

function* onLoginSuccess(action) {
  setEntitymanagerState(action)
}

function* onUserCheck(action) {
  setEntitymanagerState(action)
}

export const getState = state => state.entityManagerReducer;

const throttling = {
  maxThreshold: 15,
  currentIteration: 0,
  throttlePeriod: 10000
};

const invokeThrottler = () => {
  return new Promise((resolve, reject) => {
    if (throttling.currentIteration >= throttling.maxThreshold) {
      reject();
    }
    else if (throttling.currentIteration == 0) { 
      setTimeout(() => {
        throttling.currentIteration = 0;
      }, 10000)
      throttling.currentIteration++;
    }
    else {
      throttling.currentIteration++;
    }
  });
}

function* setEntitymanagerState(action) {
  const { error, loading, record } = yield select(getState);
  if (loading || record) {
    console.log("reattempt triggered");
    return record;
  }
  yield put(fetchEntitymanagerBegin());
  const lbtoken = storage.load('lbtoken') || {};
  try {
    if (!lbtoken.userId) throw new Error("cannot get user.");
    const entityMgr = yield fetchEntitymanagerById({id: lbtoken.userId, action});
    if (!entityMgr || !entityMgr.response.json) throw new Error(entityMgr);
	  yield put(fetchEntitymanagerSuccess(entityMgr.response.json));
  }
  catch(err) {
    yield put(fetchEntitymanagerFailure(err));
  }
}

function* onUserLoginFailure() {
	yield put(fetchEntitymanagerFailure());
}



export default function* entityManagerSaga() {
	yield all([
			takeEvery('RA/USER_LOGIN_LOADING', onUserLoginLoading),
			takeEvery('RA/USER_LOGIN_SUCCESS', setEntitymanagerState),
			takeEvery('RA/USER_LOGIN_FAILURE', onUserLoginFailure),
      //entitymanager updated
			takeEvery('RA/USER_UPDATE', setEntitymanagerState),
      //application loads
			takeEvery('RA/SET_SIDEBAR_VISIBILITY', setEntitymanagerState),
			takeEvery('RA/USER_CHECK', setEntitymanagerState),
	]);
}
