import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startUndoable, CRUD_UPDATE_MANY, UPDATE_MANY } from 'ra-core';
import {
  singularizer,
  pasttensifier,
} from './../../services';

const messagifyAction = (args) => {
  const qty = args.ids.length;
  const singularizedResource = (qty == 1) ? singularizer(args.resource) : args.resource;
  if (args.success) {
    const pastTensifiedAction = pasttensifier(args.data.status);
    return `Successfully ${pastTensifiedAction} ${qty} ${singularizedResource}.`;
  }
  else {
    return `Unable To ${args.data.status} $QTY$ ${singularizedResource}.`;
  }
}

const crudUpdateMany = (
    resource,
    ids,
    data,
    basePath,
    refresh = true
) => ({
    type: 'GGB/CUSTOM_CRUD_UPDATE_MANY',
    payload: { ids, data },
    resource,
    fetch: UPDATE_MANY,
    cancelPrevious: false,
    onSuccess: {
        notification: {
            body: messagifyAction({resource, ids, data, success: true}),
            level: 'info',
        },
        basePath,
        refresh,
        unselectAll: true,
    },
    onFailure: {
        notification: {
            body: messagifyAction({resource, ids, data, success: false}),
            level: 'warning'
        },
    },
});

class BulkAction extends Component {
    componentDidMount = () => {
        const { basePath, startUndoable, resource, selectedIds, payloadData, crudUpdateMany} = this.props;
        //startUndoable(
            crudUpdateMany(
                resource,
                selectedIds,
                payloadData,
                basePath
            )
        //);
        this.props.onExit();
    };

    render() {
        return null;
    }
}

BulkAction.propTypes = {
    basePath: PropTypes.string,
    label: PropTypes.string,
    onExit: PropTypes.func.isRequired,
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    payloadData: PropTypes.object.isRequired,
    startUndoable: PropTypes.func.isRequired,
};

export default connect(
    undefined,
    { startUndoable, crudUpdateMany }
)(BulkAction);
