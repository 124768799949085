import React from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    DisabledInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    RadioButtonGroupInput,
    LongTextInput,
    NumberInput,
    DateInput,
    AutocompleteInput,
		ImageInput,
		ImageField,
    required,
    minLength,
    maxLength,
    Toolbar,
    SaveButton,
} from 'react-admin';

import {
  xxlCharLen,
  xlCharLen,
  lCharLen,
  mCharLen,
  mainPitchCharLen
} from './../../validators';


const SubentityEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const SubentityEdit = (props) => (
  <Edit title="Edit Subentity" {...props}>
    <TabbedForm  redirect="edit" toolbar={<SubentityEditToolbar />}>

      <FormTab label="Configuration">

        <DisabledInput source="id" />

        <ReferenceInput
          label="Parent Entity"
          source="entity_id"
          reference="entities"
          perPage={999}
        >
          <DisabledInput optionText="id" translateChoice={false} />
        </ReferenceInput>

        <DisabledInput source="slug" label="Subentity Slug" />
        <RadioButtonGroupInput
          source="templating.is_searchable"
          label="Do you want subentity to be searchable?"
          choices={[
              { id: true, name: 'true' },
              { id: false, name: 'false' }
          ]}
        />

      </FormTab>

    </TabbedForm>
  </Edit>

);
