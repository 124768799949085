import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  footer: {
    width: '100%',
    height: 60,
    background: '#1E5E75',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    width: '80%',
    margin: '0 auto',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    font: '14px ProximaNova-Light',
  },
  href: {
    color: '#FFF',
    textDecoration: 'none'
  }
}

const AppFooter = () => (
  <div style={styles.footer}>
    <div style={styles.content}>
      <div>&copy; greenback {(new Date().getFullYear())}</div>
      <div><Link to="/terms" style={styles.href}>Terms & Conditions</Link></div>
    </div>
  </div>
);

export default AppFooter;
