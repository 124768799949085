import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ImageRemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { cobrandLink } from '../../services'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  }
});

const ViewButton = ({record, classes}) => (
  <Button
    href={cobrandLink({entity: record.entity, subentity_id: record.id})}
    target="_blank"
    color="primary"
    className={classes.button}>
    <ImageRemoveRedEye className={classes.leftIcon}/>
    View
  </Button>
);

ViewButton.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  entity: PropTypes.object,
};

export default withStyles(styles)(ViewButton);
