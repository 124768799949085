import React from 'react';

export const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#464D5E',
        fontFamily: "'ProximaNova-Light'"
    },
    card: {
        width: 640,
        backgroundColor: '#FFF',
        boxShadow: '2px 2px 6px rgba(0,0,0,0.2)',
        borderRadius: 5,
        padding: '50px 0'
    },
    content: {
      width: 460,
      margin: '0 auto',
      textAlign: 'center'
    },
    header: {
      fontSize: 32,
      lineHeight: 1.4,
      textAlign: 'center'
    },
    caption: {
      color: '#7E8890',
      fontSize: 18,
      margin: '20px 0',
      lineHeight: 1.4
    },
    form: {
      width: 300,
      margin: '0 auto',
    },
    input: {
      margin: '5px 0',
    },
    submit: {
      width: 175,
      fontFamily: "'ProximaNova-Light'",
      margin: '30px 0 0 0'
    },
    hint: {
      textAlign: 'center',
      marginTop: 8,
      fontSize: '14px'
    },
    href: {
      color: '#2CBBB7',
      textDecoration: 'none',
      fontFamily: "'ProximaNova-SemiBold'",
    },
    error: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#f33',
        maxWidth: 300
    },
    success: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#019490',
        maxWidth: 300
    }
    // avatar: {
    //     margin: '1em',
    //     textAlign: 'center ',
    // },
    // form: {
    //     padding: '0 1em 1em 1em',
    // },
    // input: {
    //     display: 'flex',
    // },
    // hint: {
    //     textAlign: 'center',
    //     marginTop: '1em',
    //     color: '#ccc',
    //     maxWidth: 300
    // },
    // error: {
    //     fontWeight: 'bold',
    //     textAlign: 'center',
    //     color: '#f33',
    //     maxWidth: 300
    // },
    // success: {
    //     fontWeight: 'bold',
    //     textAlign: 'center',
    //     color: '#019490',
    //     maxWidth: 300
    // }
};

export const cardStyles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  }
};

export const validators = {
  required: value => value ? undefined : 'Required',
  email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
'Invalid email address' : undefined
};
