import React from 'react';
import Button from '@material-ui/core/Button';

const style = {
  btn: {
    color: '#FFF',
    textTransform: 'capitalize',
    height: 40,
    width: 200,
    fontFamily: 'ProximaNova-SemiBold',
    borderRadius: 3,
    display: 'inline-block'
  },
  inactive: {
    display: 'hidden'
  }
}

const GreenButton = (props) => {
  return (
    <Button
      {...props}
      style={style.btn}
      backgroundColor={props.invaild ? "#AAE3E2" : "#2CBBB7"}
      label={props.label}
      hoverColor="#008f96"
      rippleColor="#125352"
      onClick={props.onClick}
      disabled={props.disabled}
    />
  )
}

export default GreenButton;
