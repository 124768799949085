import React, {createElement, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  AdminRoutes,
  Menu,
  setSidebarVisibility
} from 'react-admin';

import ReduxToastr from 'react-redux-toastr'

import AppBar from './AppBar';
import Sidebar from './Sidebar';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  body: {
    backgroundColor: '#F0F0F0',
    display: 'flex',
    flex: 1
  },
  content: {
    flex: 1,
    padding: 10
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    // margin: 16,
    zIndex: 1200,
    background: 'rgba(255,255,255,0.5)',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

class Layout extends Component {
  componentWillMount() {
    this.props.setSidebarVisibility(true);
  }

  render() {
    const {
      children,
      customRoutes,
      dashboard,
      isLoading,
      logout,
      title,
      entityMgr
    } = this.props;
    return (
      <MuiThemeProvider>
        <div style={styles.wrapper}>
          <div style={styles.main}>
            <AppBar title={title} hamburger="false" entityMgr={entityMgr} />
            <div style={styles.body}>
              <Sidebar />
              <div style={styles.content}>
                {children}
              </div>
            </div>
            <ReduxToastr />
            {isLoading && (<div style={styles.loader}><CircularProgress color="#23BFB8" size={70} thickness={7}/></div>)}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

Layout.propTypes = {
  authClient: PropTypes.func,
  customRoutes: PropTypes.array,
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  resources: PropTypes.array,
  setSidebarVisibility: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  entityMgr: state.entityManagerReducer.record
});
export default connect(mapStateToProps, {setSidebarVisibility})(Layout);
