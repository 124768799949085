import React, { Component } from 'react';
import { Resource } from 'react-admin';
import Admin from './packages/ra-core/CoreAdmin';
import loopbackRestClient, {authClient} from './aor-loopback';
import DomainIcon from '@material-ui/icons/Domain';
import PeopleIcon from '@material-ui/icons/People';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './App.css';

import Login from './routes/external/Login';

import {
  internalCustomRoutes,
  externalCustomRoutes
} from './routes';

import {
  Dashboard,
  Layout
} from './components';

import { SubentityList, SubentityEdit, SubentityCreate } from './resources/subentities';
import { UnitList, UnitEdit, UnitCreate } from './resources/units';
import { SubunitList, SubunitEdit } from './resources/subunits';

import config from './config/config.provider';

import { reducer as toastr } from 'react-redux-toastr'
import entityManagerReducer from './actions/entityManager';
import entityManagerSaga from './sagas/entityManager';
import bulkActionsSaga from './sagas/bulkActions';
import customNotificationReducer from './actions/customNotification';
import customNotificationSaga from './sagas/customNotification';
//not working?
import theme from './config/theme';

class App extends Component {
  render() {

    return (
      <Admin
        title="Management Portal"
        dataProvider={loopbackRestClient(config)}
        customRoutes={internalCustomRoutes}
        externalCustomRoutes={externalCustomRoutes}
        customSagas={[entityManagerSaga, customNotificationSaga, bulkActionsSaga]}
        customReducers={{entityManagerReducer,customNotificationReducer,toastr}}
        authProvider={authClient(`${config.apiEndpoints.entity_api}/${config.entity_api_auth_endpoint}`)}
        loginPage={Login}
        dashboard={Dashboard}
        appLayout={Layout}
        theme={theme}
      >
      {permissions => [
        <Resource name="entitymanagers" />,
        <Resource name="subentities" />,
        <Resource name="entities" />,

        ["superuser"].includes(permissions) ?
          <Resource name="ersubentities"
            options={{ label: 'Subentities' }}
            list={SubentityList}
            edit={SubentityEdit}
            create={SubentityCreate}
            icon={PeopleIcon} /> : <Resource name="ersubentities" />,

        ["superuser"].includes(permissions) ?
          <Resource name="units"
            options={{ label: 'Fundraiser Sites' }}
            list={UnitList}
            edit={UnitEdit}
            create={UnitCreate} /> : <Resource name="units" />,

        ["superuser"].includes(permissions) ?
          <Resource name="subunits"
            options={{ label: 'Booster Sites', title: 'Booster Sites' }}
            list={SubunitList}
            edit={SubunitEdit} /> : <Resource name="subunits" />,
      ]}
      </Admin>
    );
  }
}

export default App;
