import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import storage from './../../aor-loopback/storage';
import config from './../../config/config.provider';

import { Card, CardActions } from '@material-ui/core';
import { propTypes, reduxForm, Field } from 'redux-form';

import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import compose from 'recompose/compose'

import {
  LabeledInput,
  ExternalLayout,
  RaisedButton
} from './../../components';

import { Link } from "react-router-dom";

import { SubmissionError } from 'redux-form';

//Used by Login.js
import PropTypes from 'prop-types';
import { styles, validators } from './shared';

const apiClient = loopbackRestClient(config);

const { required, email } = validators;

class ForgotPassword extends Component {
  render() {
    const { 
      error, 
      pristine, 
      reset, 
      submitting, 
      dispatch, 
      handleSubmit, 
      submitSucceeded 
    } = this.props;
    return (
      <ExternalLayout>
        <div style={styles.header}>Forgot Password</div>
        <form onSubmit={handleSubmit} >
          <div style={styles.form}>
            {error && <p style={styles.error}>{config.response_messages.forgot_password_error_nonexistent_email}</p>}
            {submitSucceeded && pristine && <p style={styles.success}>{config.response_messages.forgot_password_success}</p>}
            <p style={styles.caption}>Enter your account e-mail address to be send a password reset</p>
            <div style={styles.input}>
              <Field
                name="email"
                component={LabeledInput}
                label="Username/Email"
                type="email"
                validate={[required,email]}
              />
            </div>
          </div>
          <CardActions>
            <RaisedButton type="submit" style={styles.submit} primary disabled={submitting} label="Reset Password" />
          </CardActions>
          <p style={styles.hint}>
            <Link style={styles.href} to="/login">Login</Link>
          </p>
        </form>
      </ExternalLayout>
    );
  }
};

const ForgotPasswordForm = reduxForm({
  form: 'requestReset',
  onSubmit: (values, dispatch, props) => {
    return new Promise((resolve, reject) => {
      const resetErr = { _error: 'Reset Password failed!' };
      apiClient("WITH_ROUTE", "EntityManagers", {
        route:"reset",
        data: values,
        method: "POST"
      }).then(({response}) => {
        if (response.status === 204) {
          dispatch(reset('requestReset'));
          resolve();
        }
        else {
          reject(new SubmissionError(resetErr));
        }
      }).catch((err) => {
        console.error(err);
        if (err.message.includes("not found")) {
          resetErr.email = config.response_messages.forgot_password_error_nonexistent;
        }
        reject(new SubmissionError(resetErr));
      });
    });
  }
})(ForgotPassword);

export default ForgotPasswordForm;
