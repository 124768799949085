import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import compose from 'recompose/compose'

import {
  LabeledInput,
  GreenButton
} from './../../components';

import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  showNotification,
  hideNotification,
} from './../../actions/customNotification';

import { SubmissionError } from 'redux-form';
import { validators } from '../../routes/external/shared';

const apiClient = loopbackRestClient(config);
const { required, email } = validators;

const styles = {
  save: {
    margin: '30px auto 0',
    textAlign: 'center'
  },
  error: {
    textAlign: 'center',
    color: '#f33',
    maxWidth: 300,
    fontSize: 14,
    lineHeight: 1.4,
    margin: '0 auto',
  },
  success: {
    textAlign: 'center',
    color: '#019490',
    maxWidth: 300,
    fontSize: 14,
    lineHeight: 1.4,
    margin: '0 auto',
  }
}

class AccountChangePassword extends Component {

  render() {
    const { error, pristine, reset, submitting, dispatch, handleSubmit, submitSucceeded, value } = this.props;

    return (
      <form onSubmit={handleSubmit} >
        <div>
          {error && <p style={styles.error}>{error}</p>}
          {submitSucceeded && pristine && <p style={styles.success}>{config.response_messages.change_password_success}</p>}
          <p>Change your password by using the button below to request a password reset email.</p>
      
        </div>
        <div style={styles.save}>
          <GreenButton type="submit" primary disabled={submitting} label="Change Password" />
        </div>
      </form>
    );
  }
};

let AccountChangePasswordForm = reduxForm({
  form: 'change-password',
  onSubmit: (values, dispatch, props) => {
    console.log(values)
    return new Promise((resolve, reject) => {
      const resetErr = { _error: 'Reset Password failed!' };
      apiClient("WITH_ROUTE", "EntityManagers", {
        route:"reset",
        data: values,
        method: "POST"
      }).then(({response}) => {
        if (response.status === 204) {
          dispatch(reset('requestReset'));
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
              type: "success",
              title: "Password Reset Sent",
              message: "Please check your email for instructions to change your password."
            }
          });
          resolve();
        }
        else {
          reject(new SubmissionError(resetErr));
        }
      }).catch((err) => {
        console.error(err);
        if (err.message.includes("not found")) {
          resetErr.email = config.response_messages.forgot_password_error_nonexistent;
        }
        reject(new SubmissionError(resetErr));
      });
    });
  }
})(AccountChangePassword);

AccountChangePasswordForm = connect( state => ({
  initialValues: state.entityManagerReducer.record
}))(AccountChangePasswordForm)

export default AccountChangePasswordForm;
