import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';

import { CardActions } from '@material-ui/core/Card';
import RaisedButton from '@material-ui/core/Button';
import { Field } from 'redux-form';

import { submit, reset } from 'redux-form';

import {
    formEnhancer
} from './../../services';

import {
  required,
  isValidMoment,
} from './../../validators';

import {
  LabeledInput,
  AgeCheckDateInput,
  AppBar,
} from './../../components';


import { SubmissionError } from 'redux-form';

import { styles } from './../../routes/external/shared';

const apiClient = loopbackRestClient(config);

class AccountForm extends Component {

  submit(values, dispatch, props) {
    dispatch(submit('create-account'))
  }

  render() {
    const {
      error,
      pristine,
      reset,
      submitting,
      dispatch,
      submitSucceeded
    } = this.props;
    return (
      <form>
        <div style={styles.header}>Create Account</div>
        <div style={styles.caption}>Please enter and confirm a password for your new account.</div>
        <div style={styles.form}>
          {error && <p style={styles.error}>{error}</p>}
          {submitSucceeded && pristine && <p style={styles.success}>{config.response_messages.create_account_success}</p>}
          <div style={styles.input}>
            <Field
              name="newPassword"
              component={LabeledInput}
              label="Password"
              type="password"
              validate={[required]}
            />
          </div>
          <div style={styles.input}>
            <Field
              name="newPassword_confirm"
              component={LabeledInput}
              label="Confirm Password"
              type="password"
              validate={[required]}
            />
          </div>
        </div>
        <CardActions>
          <RaisedButton
            type="submit"
            primary={true}
            disabled={submitting}
            style={styles.submit}
            label="Create Account"
            onClick={this.props.handleSubmit(this.submit)}
          />
        </CardActions>
      </form>
    );
  }
}

export const CreateAccountForm = formEnhancer({
  FormComponent: AccountForm,
  formName: 'create-account',
  validate: (values, props) => {
    const errors = {};
    if (values.newPassword !== values.newPassword_confirm)
      errors.newPassword_confirm = "Passwords do not match.";
    return errors;
  },
  onSubmit: (values, dispatch, props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {response} = await apiClient("WITH_ROUTE", "EntityManagers", {
          route:"reset-password",
          data: values,
          method: "POST"
        });
        if (response.status === 204) {
          dispatch(reset('create-account'));
          resolve();
        }
        else {
          reject(new SubmissionError({ _error: config.response_messages.reset_password_error}));
        }
      }
      catch(err) {
        if (err.code == "AGE_COPPA_NONCOMPLIANCE") {
          console.log('prevent user from submitting form again');
        }
        reject(new SubmissionError({ _error: err.message}));
      }
    });
  }
});
