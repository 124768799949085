import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {propTypes, reduxForm, Field} from 'redux-form';
import { Button } from '@material-ui/core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {
  translate, 
  userLogin as userLoginAction, 
  defaultTheme,
  TextField
} from 'react-admin';

import {
  RaisedButton,
  LabeledInput
} from './../../components';

import ExternalLayout from './../../components/Layout/ExternalLayout';

import { styles, cardStyles } from './shared';
import {Link} from 'react-router-dom';

import ReduxToastr from 'react-redux-toastr';

class Login extends Component {

  login = ({email, password}) => {
    const {userLogin, location} = this.props;
    userLogin({
      email,
      password
    },
    location.state ? location.state.nextPathname : '/');
  }

  render() {
    const {handleSubmit, submitting} = this.props;
    return (
      <ExternalLayout>
        <div style={styles.header}>Log In</div>
        <div style={styles.caption}>Enter your email address and password to access your fundraising portal.</div>
        <form onSubmit={handleSubmit(this.login)}>
          <div style={styles.form}>
            <div style={styles.input}>
              <Field
                component={LabeledInput}
                name="email"
                label="Email Address"
                hintText="youremail@domain.com"
              />
            </div>
            <div style={styles.input}>
              <Field
                component={LabeledInput}
                name="password"
                label="Password"
                hintText="Testing123"
                type="password"
              />
            </div>
          </div>
          <RaisedButton type="submit" style={styles.submit} primary disabled={submitting} label="Log In"  />
          <p style={styles.hint}>
            <Link style={styles.href} to="/forgot_password">Forgot Password?</Link>
          </p>
        </form>
      </ExternalLayout>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
};

Login.defaultProps = {};

const enhance = compose(translate, reduxForm({
  form: 'signIn',
  validate: (values, props) => {
    const errors = {};
    const {translate} = props;
    if (!values.email)
      errors.email = "Required";
    if (!values.password)
      errors.password = "Required";
    return errors;
  }
}), connect(null, {userLogin: userLoginAction}),);

export default enhance(Login);
