import React from 'react';
import { Button } from '@material-ui/core';

export const RaisedButton = (props) => {
  return (
    <Button 
    {...props}
    >
      {props.label}
    </Button>
  );
};
