import React from 'react';
import { TextInput } from 'react-admin';

const styles = {
  root: {
    fontSize: 14,
    height: 55,
    fontFamily: "'ProximaNova-Light'",
  },
  label: {
    top: 23,
    color: '#666'
  },
  input: {
    marginTop: 7
  },
  error: {
    lineHeight: 'inhert',
    textAlign: 'left',
    fontSize: 10,
  },
  textarea: {
    marginTop: 32,
  }
}

const LabeledInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => {
  return (
    <div>
      <strong>{error}</strong>
      <TextInput
        source={inputProps.name}
        type={props.type}
        label={props.label}
        validate={props.validate}
        style={props.style}
        elStyle={props.style}
      />
    </div>
  )
}

export default LabeledInput;
