import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

const EditButton = ({ basePath = '', record = {}, classes }) => (
    <Button
        component={Link} 
        to={`${basePath}/${record.id}`}
        color="primary" 
        className={classes.button}
        >
      <CreateIcon className={classes.leftIcon} />
      Edit
    </Button>
);

EditButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
};

export default withStyles(styles)(EditButton);
