import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Menu from './Menu';

import {
  setSidebarVisibility as setSidebarVisibilityAction,
  Responsive,
  MenuItemLink,
  getResources,
  DashboardMenuItem
} from 'react-admin';

const styles = {
    sidebarOpen: {
        width: 200,
        marginLeft: 0,
        // transition: 'left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    },
    sidebarClosed: {
        marginLeft: -144,
        // transition: 'left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    },
    activeStyle: {

    },
    inactiveStyle: {

    },
    sidebar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'

    },
    sidebarHeader: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
      backgroundColor: '#F2F7F7',
      color: '#9B9B9B',
      fontSize: 16
    },
    copyright: {
      margin: 10,
      font: '12px ProximaNova-Light',
      color: '#95989A',
    },
    link: {
      font: '12px ProximaNova-Light',
      color: '#95989A',
      textDecoration: 'none',
      marginTop: 10,
      display: 'inline-block'
    }
};

// {React.cloneElement(children, {
//     onMenuTap: () => null,
// })}


class Sidebar extends PureComponent {
  handleClose = () => {
    this.props.setSidebarVisibility(false);
  };


  render() {
    const { open, setSidebarVisibility, children } = this.props;

    return (
        <Paper style={open ? styles.sidebarOpen : styles.sidebarClosed}>
          <div style={styles.sidebar}>
            {React.cloneElement(
              <div>
                <div style={styles.sidebarHeader}>Manage Sites</div>
                <Menu />
              </div>
            )}
            <div style={styles.copyright}>
              &copy; greenback {(new Date().getFullYear())}
              <br />
              <Link style={styles.link} to={`/`}>privacy policy</Link> / <Link style={styles.link} to={`/`}>terms & conditions</Link>
            </div>
          </div>
        </Paper>
    );
  }
}

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    setSidebarVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
    open: state.admin.ui.sidebarOpen,
    locale: state.locale, // force redraw on locale change
    theme: props.theme, // force redraw on theme changes
});

export default compose(
    connect(mapStateToProps, {
        setSidebarVisibility: setSidebarVisibilityAction,
    })
)(Sidebar);
