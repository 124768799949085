export default function pasttensifier(verb) {
  switch(verb) {
    case "approve":
      return "approved";
    case "deny":
      return "denied";
    case "activate":
      return "activated";
    case "deactivate":
      return "deactivated";
    default:
      return verb;
  }
}
