import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';

const styles = {
  root: {
    fontSize: 14,
    height: 55,
    fontFamily: "'ProximaNova-Light'"
  },
  label: {
    top: 23,
    color: '#666'
  },
  input: {
    marginTop: 7
  },
  error: {
    lineHeight: 'inhert',
    textAlign: 'left',
    fontSize: 10
  },
  textarea: {
    marginTop: 32
  }
}

class NumberedInput extends Component {
  handleBlur = () => {
    const value = isNaN(parseFloat(this.props.input.value))
      ? null
      : parseFloat(this.props.input.value);
    this.props.onBlur(value);
    this.props.input.onBlur(value);
  };

  handleFocus = event => {
    this.props.onFocus(event);
    this.props.input.onFocus(event);
  };

  handleChange = (event, newValue) => {
    const value = isNaN(parseFloat(newValue))
      ? null
      : parseFloat(newValue);
    this.props.onChange(value);
    this.props.input.onChange(value);
  };

  render() {
    const {
      elStyle,
      input,
      isRequired,
      label,
      meta,
      options,
      source,
      step,
      resource,
      hintText
    } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error("The NumberInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/admin-on-rest/Inputs.html#writing-your-own-input-component for details.");
    }
    const {touched, error} = meta;

    return (
      <TextField
        {...input}
        label={label}
        hintText={hintText}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        type="number"
        step={step}
        errorText={touched && error}
        {...options}
        fullWidth="fullWidth"
        style={styles.root}
        floatingLabelStyle={styles.label}
        inputStyle={styles.input}
        errorStyle={styles.error}
      />
    );
  }
}

NumberedInput.propTypes = {
  addField: PropTypes.bool.isRequired,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ])
};

NumberedInput.defaultProps = {
  addField: true,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  step: 'any'
};

export default NumberedInput;
